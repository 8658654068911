import _ from 'lodash';
import * as utils from '@wix/santa-editor-utils';
import experiment from 'experiment';

import type { EditorAPI } from '@/editorAPI';
import type { BiEventDefinition, SendBiFunction } from 'types/bi';

/**
 * Currently translation file[1] size is 1.6 - 2.2 MB. There is a big chance that half of this keys are not used.
 * By reporting used translation keys to the BI, we can find unused keys and remove them.
 *
 * NOTE: 'requestIdleCallback'[2] is used to not concur with user experience.
 * It's not a problem, that some browsers, does not support `requestIdleCallback`, because data from browsers with support is enought.
 *
 * [1]: https://static.parastorage.com/services/santa-langs/1.13381.0/resources/santa-editor/bundles/_generated/santa_editor_uk.json
 * [2]: https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
 */

const isReportEnableed =
  experiment.isOpen('se_reportTranslationKeyUsage') &&
  'requestIdleCallback' in window &&
  !utils.url.isQA();

let isReportInitialized = false;
let sendBi: SendBiFunction;

const translationKeysToSend = new Set();
const translationKeysProcessed = new Set(
  // NOTE: skip reporting translation keys from the often used UIs (we already know about this keys).
  require('./translationKeysProcessed.json'),
);

const BI_EVENT_DEFINITION: BiEventDefinition = {
  evid: 1122,
  src: 38,
  fields: {
    translationKeys: 'string',
  },
};

let reportTaskHandle: number;
const scheduleReport = _.debounce(() => {
  if (!reportTaskHandle) {
    reportTaskHandle = (window as any).requestIdleCallback(() => {
      // Slice `maxKeysToSend` items, to be sure bi event is send without `413 Entity Too Large` error (too long URL)
      const maxKeysToSend = 200;
      const keysToSendBatch = Array.from(translationKeysToSend).slice(
        0,
        maxKeysToSend,
      );

      sendBi(BI_EVENT_DEFINITION, {
        translationKeys: keysToSendBatch.join(','),
      });

      keysToSendBatch.forEach((key) => {
        translationKeysToSend.delete(key);
      });

      if (translationKeysToSend.size > 0) {
        scheduleReport();
      }

      reportTaskHandle = undefined;
    });
  }
}, 1000);

export function reportTranslationUsage(translationKey: string): void {
  if (!isReportEnableed || translationKeysProcessed.has(translationKey)) {
    return;
  }

  translationKeysProcessed.add(translationKey);
  translationKeysToSend.add(translationKey);

  if (isReportInitialized) {
    scheduleReport();
  }
}

export function initTranslationUsageReporting(editorAPI: EditorAPI): void {
  if (!isReportEnableed) {
    return;
  }

  sendBi = editorAPI.dsActions.bi.event;
  isReportInitialized = true;

  if (translationKeysToSend.size) {
    scheduleReport();
  }
}
